import React, { useCallback, useEffect, useState } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { Card, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Loader from "components/Loader";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ExpandableTable from "examples/Tables/ExpandableTable";
import { useLazyGetNewOrdersQuery } from "services/new-order.service";
import { useUpdateOrderMutation } from "services/order.service";
import { toast } from "react-toastify";
import { toPng } from "html-to-image";
import { jsPDF } from "jspdf";
function CurrentOrder() {
  const { t } = useTranslation();

  const [orders, setOrders] = useState({
    total: 0,
    data: [],
  });
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [printItem, setPrintItem] = useState();

  const [getOrders] = useLazyGetNewOrdersQuery();
  const [updateOrder] = useUpdateOrderMutation();
  const [intervalId, setIntervalId] = useState(null);
  const fetchOrders = useCallback(async () => {
    setLoading(true);
    const { data, isSuccess, isError, error } = await getOrders();
    if (isSuccess) {
      setOrders(data);
      if (data.data.length > 0) {
        stopInterval();
        printOrders(0, data.data);
      } else {
        startInterval();
      }
    }
    if (isError) {
      console.log(error);
    }
    setLoading(false);
  });

  useEffect(() => {
    fetchOrders();
  }, []);

  const printOrders = (i, data) => {
    if (!data || !data[i]) {
      console.error("Invalid data or index");
      return;
    }

    try {
      console.log("Processing order:", data[i]);
      const currentItem = {
        ...data[i],
        mobile: data[i]?.user?.mobile || data[i]?.mobile || "",
      };
      setPrintItem(currentItem);

      // Use the currentItem directly
      setTimeout(() => {
        if (!currentItem) {
          console.log("Print item not set yet, retrying...");
          setTimeout(() => {
            SaveAsTextHandler(i, data, currentItem); // Pass currentItem directly
          }, 1000);
          return;
        }
        SaveAsTextHandler(i, data, currentItem); // Pass currentItem directly
      }, 1500);
    } catch (error) {
      console.error("Error in printOrders:", error);
    }
  };

  const startInterval = () => {
    const id = setInterval(() => {
      fetchOrders();
    }, 10000);
    setIntervalId(id);
  };

  const stopInterval = () => {
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
    }
  };

  const handleOrderUpdate = async (data) => {
    const ordersClone = { ...orders };

    ordersClone.data = ordersClone?.data?.map((product) => {
      if (product?.id === data?.id) {
        product = { ...product, is_printed: data?.is_printed };
      }
      return product;
    });
    setOrders(ordersClone);

    const result = await updateOrder({
      submitData: { status: data?.updatedStatus },
      id: data?.id,
    });
    if ("error" in result) {
      toast.error(result?.error?.data?.message || t("common:SOMETHING_WENT_WRONG"));
    } else {
      toast.success(result?.data?.msg);
    }
  };

  const SaveAsTextHandler = (i, data, currentItem = printItem) => {
    // Use passed item or fallback to state
    if (!currentItem) {
      toast.info("No print item available");
      return;
    }

    try {
      // Create text content using currentItem
      const textContent = [
        "SOUL PIZZA",
        "GST No. 111-111-111",
        "",
        `Order Type: ${
          currentItem?.type
            ? currentItem.type.charAt(0).toUpperCase() + currentItem.type.slice(1)
            : "N/A"
        }`,
        `Order Time: ${new Date(currentItem?.createdAt).toLocaleString()}`,
        `Customer: ${currentItem?.customer_name || "N/A"}`,
        `Contact: ${currentItem?.mobile || "N/A"}`,
        `Pickup Time: ${new Date(currentItem?.pickup_time).toLocaleString()}`,
        `Invoice Number: ${currentItem?.id}`,
        "",
        "ORDER DETAILS",
        "----------------------------------------",
        "ITEM               QTY    PRICE    AMOUNT",
        "----------------------------------------",
        ...(currentItem?.orderItems?.map(
          (item) =>
            `${(item?.product?.name || "Unknown").padEnd(18)} ${String(
              item?.quantity || 0
            ).padStart(3)} ${`$${Number(item?.price || 0).toFixed(2)}`.padStart(8)} ${`$${Number(
              (item?.price || 0) * (item?.quantity || 1)
            ).toFixed(2)}`.padStart(8)}`
        ) || []),
        "----------------------------------------",
        `Subtotal:${`$${Number(currentItem?.amount - (currentItem?.tax || 0)).toFixed(2)}`.padStart(
          32
        )}`,
        `Total:${`$${Number(currentItem?.amount || 0).toFixed(2)}`.padStart(35)}`,
        "",
        "Delivery Address:",
        `${currentItem?.address || "N/A"}`,
      ].join("\n");

      // Create and download text file
      const blob = new Blob([textContent], { type: "text/plain" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `invoice-${currentItem?.id}.txt`;

      document.body.appendChild(link);

      setTimeout(() => {
        try {
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);

          setTimeout(() => {
            if (i < data.length - 1) {
              printOrders(i + 1, data);
            }
          }, 1000);
        } catch (clickError) {
          console.error("Error clicking download link:", clickError);
        }
      }, 500);
    } catch (error) {
      console.error("Error saving text file:", error);
      if (i < data.length - 1) {
        printOrders(i + 1, data);
      }
    }
  };

  const cellList = [
    { id: "blank", name: "", alignment: "", selector: "" },
    { id: "orderId", name: "Order ID", alignment: "center", selector: "id", isId: true },
    { id: "date", name: "Date", alignment: "center", selector: "createdAt", isDate: true },
    {
      id: "customerName",
      name: "Customer Name",
      alignment: "center",
      selector: "customer_name",
      isText: true,
    },
    // { id: 'orderType', name: "Order Type", alignment: "center", selector: "type", isText: true },
    // { id: 'address', name: "Address", alignment: "center", selector: "address", isText: true },
    {
      id: "pickupTime",
      name: "Pick-up Time",
      alignment: "center",
      selector: "pickup_time",
      isDate: true,
    },
    { id: "amount", name: "Amount", alignment: "right", selector: "amount", isPrice: true },
    // { id: 'status', name: "Status", alignment: "center", selector: "status", isBadge: true },
  ];

  const subCellList = [
    {
      id: "productName",
      name: "Product Name",
      alignment: "",
      selector: "product.name",
      isText: true,
    },
    { id: "quantity", name: "Quantity", alignment: "center", selector: "quantity", isText: true },
    {
      id: "defaultIngredients",
      name: "Default Ingredients",
      alignment: "",
      selector: "requiredIngrediants",
      isChip: true,
    },
    {
      id: "customIngredients",
      name: "Customized Ingredients",
      alignment: "",
      selector: "customIngrediants",
      isChip: true,
    },
    { id: "size", name: "Size", alignment: "center", selector: "size", isText: true },
    {
      id: "crustType",
      name: "Crust Type",
      alignment: "center",
      selector: "crust_type",
      isCrust: true,
    },
    {
      id: "glutenFree",
      name: "Gluten Free",
      alignment: "center",
      selector: "gluten_free",
      isBoolean: true,
    },
    { id: "amount", name: "Amount", alignment: "right", selector: "price", isPrice: true },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDTypography variant="h6" color="white">
                  {t("Current Order")}
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <ExpandableTable
                  orderList={orders?.data}
                  count={orders?.total || 0}
                  cellList={cellList}
                  subCellList={subCellList}
                  expandTitle={t("order:ORDER_DETAILS_TITLE")}
                  expandProperty="orderItems"
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  page={page}
                  setPage={setPage}
                  setSearch={setSearch}
                  handleOrderUpdate={handleOrderUpdate}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {/* <Loader open={loading} /> */}
      <Footer />
      {printItem && (
        <div className="p-4" id="print">
          <h1 className="text-center text-lg font-bold text-gray-900">SOUL PIZZA</h1>
          <h1 className="text-center text-lg font-bold text-gray-900">GST No. 111-111-111</h1>
          <div className="mt-6">
            <div className="mb-4 grid grid-cols-2">
              <span className="font-bold">Order Type</span>
              <span>
                {printItem?.type
                  ? printItem.type.charAt(0).toUpperCase() + printItem.type.slice(1)
                  : "N/A"}
              </span>
              <span className="font-bold">Order Time:</span>
              <span>{new Date(printItem.createdAt).toLocaleString()}</span>
              <span className="font-bold">Customer:</span>
              <span>{printItem.customer_name}</span>
              <span className="font-bold">Contact:</span>
              <span>{printItem.mobile}</span>
              <span className="font-bold">Pickup Time:</span>
              <span>{new Date(printItem.pickup_time).toLocaleString()}</span>
              <span className="font-bold">Invoice Number:</span>
              <span>{printItem.id}</span>
            </div>

            <table className="w-full text-left">
              <thead>
                <tr className="border-y border-black/10 text-sm md:text-base">
                  <th>ITEM</th>
                  <th className="text-center">QTY</th>
                  <th className="text-right">PRICE</th>
                  <th className="text-right">AMOUNT</th>
                </tr>
              </thead>
              <tbody>
                {printItem?.orderItems?.map((item, index) => (
                  <tr key={item?.id || `order-item-${index}`}>
                    <td className="w-full">
                      {typeof item?.product === "object" ? item?.product?.name : "Unknown"}
                      {item?.requiredIngrediants?.map((ing, idx) => (
                        <span
                          key={`req-ing-${item?.id}-${idx}`}
                          className="text-sm text-gray-600 block"
                        >
                          - {typeof ing === "object" ? ing?.name : ing}
                        </span>
                      ))}
                      {item?.customIngrediants?.map((ing, idx) => (
                        <span
                          key={`custom-ing-${item?.id}-${idx}`}
                          className="text-sm text-gray-600 block"
                        >
                          + {typeof ing === "object" ? ing?.name : ing}
                        </span>
                      ))}
                    </td>
                    <td className="min-w-[50px] text-center">{item?.quantity}</td>
                    <td className="min-w-[80px] text-right">
                      ${Number(item?.price || 0).toFixed(2)}
                    </td>
                    <td className="min-w-[90px] text-right">
                      ${Number((item?.price || 0) * (item?.quantity || 1)).toFixed(2)}
                    </td>
                  </tr>
                )) || []}
              </tbody>
            </table>

            <div className="mt-4 flex flex-col items-end space-y-2">
              <div className="flex w-full justify-between border-t border-black/10 pt-2">
                <span className="font-bold">Subtotal:</span>
                <span>${(printItem.amount - printItem.tax).toFixed(2)}</span>
              </div>
              {/* <div className="flex w-full justify-between">
                <span className="font-bold">Tax:</span>
                <span>${printItem.tax.toFixed(2)}</span>
              </div> */}
              <div className="flex w-full justify-between border-t border-black/10 py-2">
                <span className="font-bold">Total:</span>
                <span className="font-bold">${(printItem?.amount || 0).toFixed(2)}</span>
              </div>
            </div>
            <div className="flex-col grid grid-cols-1">
              <span className="font-bold">Delivery Address</span>
              <span>{printItem.address}</span>
            </div>
          </div>
        </div>
      )}
    </DashboardLayout>
  );
}

export default CurrentOrder;
