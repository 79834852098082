/* eslint-disable react/prop-types */
import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

function DropDown({ onChange, onBlur, value, error, isViewOnly, option, label }) {
  return (
    <FormControl fullWidth>
      <InputLabel id={`${label}-label`} error={error}>
        {label}
      </InputLabel>
      <Select
        labelId={`${label}-label`}
        id={label?.toLowerCase()}
        label={label}
        value={value}
        name={label?.toLowerCase()}
        sx={{ height: 43 }}
        onChange={onChange}
        onBlur={onBlur}
        fullWidth
        error={error}
        disabled={isViewOnly}
      >
        {option?.map((item, index) => (
          <MenuItem key={index} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default DropDown;
